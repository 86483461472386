import React from 'react'
import { TextLink } from '@atoms/Typography'
import styled, { breakpoints, css } from '@xstyled/styled-components'

const BackButton = ({ data }) => {
  const { button, buttonLink } = data
  return (
    <S.TextLink internal to={buttonLink}>
      {button}
    </S.TextLink>
  )
}

const S = {}

S.TextLink = styled(TextLink)`
  line-height: 1.4;
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    md: css`
      font-size: 19;
    `,
  })}
`
export default BackButton
