import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@organisms/Layout'
import BackButton from '@organisms/404/BackButton'
import Copy from '@organisms/404/Copy'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import idgen from '@utils/idgen'
import Image from '@organisms/404/Image'

const NotFoundPage = ({ data }) => {
  const { blocks, meta } = data.notFound.nodes[0].frontmatter
  return (
    <Layout hamburgerMenu title={meta.title} buttonColor='dark' customBackgroundColor='pink'>
      <S.Main>
        {blocks.map(item => {
          switch (item.type) {
            case 'image':
              return <Image key={idgen()} data={item} />
            case 'copy':
              return <Copy data={item} key={idgen()} />
            case 'button':
              return <BackButton data={item} key={idgen()} />
            default:
              return null
          }
        })}
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.pink')};
  display: flex;
  align-items: center;
  flex-direction: column;
  ${breakpoints({
    xs: css`
      padding-top: 154;
      padding-bottom: 192;
    `,
    md: css`
      padding-top: 154;
      padding-bottom: 231;
    `
  })}
`

export const pageQuery = graphql`
  query NotFoundPage {
    notFound: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "404" } } }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
  }
`

export default NotFoundPage
