import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { Container } from '@atoms/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'

const Image = ({ data }) => {
  const { image } = data
  return (
    <S.Wraper>
      <S.Img
        image={image.childImageSharp.gatsbyImageData}
        title="404 Error | Lost"
      />
    </S.Wraper>
  )
}

const S = {}

S.Wraper = styled(Container)`
  text-align: -webkit-center;
`

S.Img = styled(GatsbyImage)`
  object-fit: cover;
  ${breakpoints({
    xs: css`
      height: auto;
      max-width: 325;
      margin: 0 -30 50 -30;
    `,
    sm: css`
      margin: 0;
      max-width: 325;
      height: auto;
    `,
    md: css`
      margin: 0;
      max-width: 325;
      height: auto;
    `,
    lg: css`
      margin: 0;
      width: 100%;
    `,
  })}
`

export default Image
