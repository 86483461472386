import React from 'react'
import { H1, TitleContent } from '@atoms/Typography'
import { Container } from '@atoms/Grid'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'

const Copy = ({ data }) => {
  const { title, paragraphs } = data
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Content>{paragraphs}</S.Content>
    </S.Container>
  )
}

const S = {}

S.Container = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`

S.Title = styled(H1)`
  line-height: 1;
  ${breakpoints({
    xs: css`
      font-size: 100;
      padding-bottom: 10;
    `,
    md: css`
      font-size: 100;
      font-weight: ${th('fontWeights.black')};
    `,
  })}
`

S.Content = styled(TitleContent)`
  font-weight: ${th('fontWeights.normal')};
  line-height: 1.5;
  ${breakpoints({
    md: css`
      font-size: 20;
    `,
  })}
`

export default Copy;